import { UrlParams } from 'Utils';

const whos = [
  {
    value: 'sysdream',
    radioLabel: 'Je suis un client SysDream',
  },
  {
    value: 'cert-aviation-france',
    radioLabel:'Je suis adhérent au CERT Aviation France',
  }
]

const wishes = [
  {
    value: 'incass',
    radioLabel: 'Je souhaite déclarer un incident ou effectuer une demande d\'assistance pour une levée de doute'
  },
  {
    value: 'exo',
    radioLabel: 'Je participe à un exercice (Cyber Entraînement)'
  },
  {
    value: 'dida',
    radioLabel: 'Je souhaite consulter une déclaration d\'incident ou suivre ma demande d\'assistance'
  
  }
]

function handlePageBackground() {
  const value = UrlParams.get('who', 'sysdream');
  
  if (value === 'cert-aviation-france') {
    document.body.classList.add('caf_bg');
    window.dispatchEvent(new CustomEvent('web-particles-toggle', { detail: { show: false } }));
  } else {
    document.body.classList.remove('caf_bg');
    window.dispatchEvent(new CustomEvent('web-particles-toggle', { detail: { show: true } }));
  }
}

function isWhoAllowed(value) {
  return whos.filter(who => who.value === value) ? true : false;
}

function isWishAllowed(value) {
  return wishes.filter(wish => wish.value === value) ? true : false;
}

function getWishTitle(id) {
  return wishes.filter(wish => wish.value === id)[0].radioLabel;
}

export default {
  handlePageBackground,
  isWhoAllowed,
  isWishAllowed,
  whos,
  wishes,
  getWishTitle
}
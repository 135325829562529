import { PageElement, html, css } from 'Elements';

class Error403 extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .container {
          padding:20px;
          text-align:center;
        }

        p {
          font-size:1em;
        }

        h3 m-icon {
          float:left;
          margin-right:7px;
        }

        .container m-icon {
          display:block;
          text-align:center;
          font-size:60px;
          color:var(--sl-color-danger-500);
        }
      `
    ];
  }
  
  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'403 - Forbidden',
            text:'This page requires permissions you do not have.',
            back:'Back',
          }
        },
        french:{
          translation: {
            title:'403 - Accès refusé',
            text:'Cette page nécessite des permissions que vous ne possédez pas.',
            back:'Retour',
          }
        }
      }
    ]
  }

  render() {
    return html`
      <section-header micon="error">${this._tl('title')}</section-header>
      <br/>
      <div class="container">
        <m-icon name="block"></m-icon>
        <p>${this._tl('text')}</p>
        <br/>
        <sl-button @click="${this.goHome}" size="medium">${this._tl('back')}</sl-button>
      </div>
    `;
  }

  goHome() {
    document.location.href = '/';
  }

}

customElements.define('error-forbidden', Error403);
import { html, css } from 'Elements';
import { Router } from 'Router';
import { Fetcher, UrlParams, Sleep } from 'Utils';

import utils from './utils.js';
import Step from './Step.js';

class Step3 extends Step {
  static get styles() {
    return [
      super.styles,
      css`
        form {
          padding-left:20px;
        }

        .align {
          display: flex;
          justify-content: flex-start;
          margin-bottom:10px;
        }

        .align sl-input {
          width:340px;
        }

        @media (max-width: 670px) {
          .align sl-input {
            width:340px;
          }
        }

        .align > * {
          margin-right:10px;
        }

        label {
          line-height:2em;
          min-width:140px;
          white-space:nowrap;
          display:inline-block;
        }

        .wish {
        }

        m-icon[name="help"] {
          color:var(--sl-color-neutral-400);
          cursor:pointer;
          line-height:27px;
          font-size:1.8em;
        }

        m-icon[name="help"]:hover {
          color:var(--sl-color-primary-500);
        }

        .bt_icon::part(base)   {
          line-height:27px;
          min-height:initial;
          line-height:initial ;
        }

        .bt_icon::part(label) {
          margin:0px;
          padding:0px;
        }

        #loader {
          text-align:center;
        }

        #loader img {
          animation: rotate 5s linear infinite;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .modal_dialog::part(header-actions) {
          display:none;
        }

        modal-dialog::part(title) {
          padding:10px;
        }

        modal-dialog::part(body) {
          padding:10px;
          overflow:hidden;
          min-height:140px;
        }

        .center {
          text-align:center;
        }

        .tel {
          display:inline-block;
          border:1px solid red;
        }
      `
    ];
  }

  static get properties() {
    return {
      modalSubmit: { type: Boolean },
      modalError: { type: Boolean },
      modalForbidden: { type: Boolean },
      modalHelpEmail: { type: Boolean },
      modalHelpDossier: { type: Boolean }
    };
  }

  constructor() {
    super();
    this.modalSubmit = false;
    this.modalError = false;
    this.modalForbidden = false;
    this.modalHelpEmail = false;
    this.modalHelpDossier = false;
  }

  async connectedCallback() {
    super.connectedCallback();

    this.who = UrlParams.get('who', 'sysdream');
    this.wish = UrlParams.get('wish', 'dida');

    if (!utils.isWhoAllowed(this.who)) {
      Router.go('/public');
      return;
    }

    if (!utils.isWishAllowed(this.wish)) {
      Router.go('/public');
      return;
    }
  }

  async firstUpdated() {
    super.firstUpdated(); 
    this.ticketField = this.shadowRoot.querySelector('#ticket');
    this.emailField = this.shadowRoot.querySelector('#email');
    this.loader = this.shadowRoot.querySelector('#loader');
    this.btNext = this.shadowRoot.querySelector('#bt_next');

    await Sleep(100);
    this.emailField && this.emailField.focus();
  }

  onInput(e) {
    e.preventDefault();
    e.stopPropagation();

    this.ticketField.setCustomValidity('');
    this.emailField.setCustomValidity('');

    if (this.ticketField.value.length < 3) {
      this.btNext.disabled = true;
      return;
    }

    if (this.emailField.value.length < 3) {
      this.btNext.disabled = true;
      return;
    }

    this.btNext.disabled = false;
    
  }

  async handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.emailField.checkValidity()) return;
    if (!this.ticketField.checkValidity()) return;

    this.modalSubmit = true;
    let response;

    try {
      response = await Fetcher.post('public/rt/ticket/check', {
        ticket: this.ticketField.value,
        email: this.emailField.value
      })
    } catch(e) {
      console.log(e);
      this.closeDialog();
      this.modalError = true;
      return;
    }

    this.closeDialog();

    if (!response) {
      this.modalError = true;
      return;
    }

    if (!response.ok) {
      if (response.error === 'EEMAIL_INVALID') {
        this.emailField.setCustomValidity('Adresse email invalide');  
        this.emailField.reportValidity();
        this.emailField.focus();
      }

      if (response.error === 'ETICKET_INVALID') {
        this.ticketField.setCustomValidity('Numéro de ticket invalide');  
        this.ticketField.reportValidity();
        this.ticketField.focus();
      }

      if (response.error === 'ETICKET_NOTFOUND') {
        this.ticketField.setCustomValidity('Dossier introuvable, veuillez vérifier le numéro.');  
        this.ticketField.reportValidity();
        this.ticketField.focus();
      }

      if (response.error === 'EFORBIDDEN') {
        this.modalForbidden = true;
      }

      return;
    }

    Router.go('/public/mondossier/etape4'+window.location.search);

  }

  showHelpTicket() {
    this.modalHelpDossier = true;
  }

  showHelpEmail() {
    this.modalHelpEmail = true;
  }

  closeDialog() {
    this.modalSubmit = false;
    this.modalError = false;
    this.modalForbidden = false;
    this.modalHelpEmail = false;
    this.modalHelpDossier = false;
  }

  render() {
    return html`
      <section-header img="assets/img/dossier.png">${document.title}</section-header>

      <form @submit="${this.handleSubmit}">
        <br/>
        <div class="wish">${utils.getWishTitle(this.wish)}.</div>
        <br/><br/>
        <div class="align">
          <sl-input placeholder="Votre email" size="small" type="email" required minlength="3" name="email" id="email" @sl-input="${this.onInput}"></sl-input>
          <sl-button @click="${this.showHelpEmail}" variant="text" class="bt_icon"><m-icon name="help"></m-icon></sl-button>
        </div>
        <div class="align">
          <sl-input placeholder="Numéro de dossier" size="small" type="number" required minlength="3" no-spin-buttons name="ticket" maxlength="5" id="ticket" @sl-input="${this.onInput}"></sl-input>
          <sl-button @click="${this.showHelpTicket}" variant="text" class="bt_icon"><m-icon name="help"></m-icon></sl-button>
        </div>
      </form>

      <hr-custom></hr-custom><br/>

      <div class="buttons">
        <sl-button @click="${this.goPreviousStep}" variant="text">Précédent</sl-button>
        <sl-button @click="${this.handleSubmit}" variant="primary" id="bt_next" disabled>
          <m-icon slot="suffix" name="navigate_next" nogradient></m-icon>
          Suivant
        </sl-button>
      </div>

      <modal-dialog label="Numéro de dossier" .open=${this.modalHelpDossier} @after-hide=${this.closeDialog} >
        <p>Le numéro de dossier est un nombre d'au moins 3 chiffres. Exemple: 429</p>
        <sl-button slot="bt1" variant="text" close="true">Fermer</sl-button>
      </modal-dialog>

      <modal-dialog label="Votre email" .open=${this.modalHelpEmail} @after-hide=${this.closeDialog} >
        <p>Votre adresse mail doit être inscrite en tant que personne autorisée.</p>
        <sl-button slot="bt1" variant="text" close="true">Fermer</sl-button>
      </modal-dialog>

      <modal-dialog .open=${this.modalSubmit} modal class="modal_dialog">
        <p class="center">
          Vérification des autorisations sur ce dossier, merci de patienter.<br/><br/>
        </p>
        <sl-progress-bar indeterminate></sl-progress-bar>
      </modal-dialog>

      <modal-dialog label="Erreur" .open=${this.modalError} @after-hide=${this.closeDialog} class="error_dialog">
        <m-icon slot="micon" name="sync_problem" nogradient></m-icon>
        <p class="center">  
          Une erreur est survenue pendant la vérification de votre dossier.<br/><br/>
          Merci de réessayer ultérieurement.
        </p>
      </modal-dialog>

      <modal-dialog label="Accès refusé" .open=${this.modalForbidden} @after-hide=${this.closeDialog} class="error_dialog">
        <m-icon slot="micon" name="do_not_disturb_on" nogradient></m-icon>
        En raison de la stratégie de déclaration d'incident relative au CERT SysDream, <br/>
        l'accès à ce dossier vous a été refusé.<br/><br/>
        Merci de bien vouloir prendre contact avec le déclarant originel de l'incident.<br/><br/>
        N'hésitez pas à nous contacter également au <a href="bla">+33(0)1 83 07 00 06</a> pour plus d'informations.
      </modal-dialog>
    `;
  }

  goPreviousStep() {
    Router.go('/public/mondossier/etape2'+window.location.search);
  }

  goNextStep() {
    Router.go('/public/mondossier/etape4'+window.location.search);
  }

}

customElements.define('page-dossier-etape3', Step3);